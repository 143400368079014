<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 17.4766C7.3235 17.4766 5.68464 16.9794 4.29068 16.048C2.89671 15.1166 1.81025 13.7927 1.16868 12.2438C0.52711 10.695 0.359246 8.9906 0.686316 7.34631C1.01339 5.70202 1.8207 4.19164 3.00617 3.00617C4.19164 1.8207 5.70202 1.01339 7.34631 0.686316C8.9906 0.359246 10.695 0.52711 12.2438 1.16868C13.7927 1.81025 15.1166 2.89671 16.048 4.29068C16.9794 5.68464 17.4766 7.3235 17.4766 9C17.4737 11.2472 16.5797 13.4016 14.9906 14.9906C13.4016 16.5797 11.2472 17.4737 9 17.4766ZM9 2.16407C7.64798 2.16407 6.32632 2.56499 5.20216 3.31613C4.078 4.06727 3.20182 5.1349 2.68442 6.384C2.16702 7.63311 2.03165 9.00759 2.29542 10.3336C2.55918 11.6597 3.21024 12.8777 4.16627 13.8337C5.12229 14.7898 6.34034 15.4408 7.66638 15.7046C8.99242 15.9684 10.3669 15.833 11.616 15.3156C12.8651 14.7982 13.9327 13.922 14.6839 12.7978C15.435 11.6737 15.8359 10.352 15.8359 9C15.8359 8.1023 15.6591 7.21338 15.3156 6.384C14.972 5.55463 14.4685 4.80104 13.8337 4.16627C13.199 3.53149 12.4454 3.02796 11.616 2.68442C10.7866 2.34088 9.89771 2.16407 9 2.16407Z"
      fill="#D50525"
    />
    <path
      d="M7.81875 4.78906C8.59135 4.57241 9.40865 4.57241 10.1813 4.78906L9.57969 10.0938H8.42031L7.81875 4.78906ZM10.0938 12.2812C10.0938 12.4976 10.0296 12.709 9.90942 12.8889C9.78924 13.0688 9.61842 13.209 9.41856 13.2917C9.21871 13.3745 8.99879 13.3962 8.78662 13.354C8.57446 13.3118 8.37957 13.2076 8.2266 13.0546C8.07364 12.9017 7.96947 12.7068 7.92727 12.4946C7.88507 12.2825 7.90672 12.0625 7.98951 11.8627C8.07229 11.6628 8.21248 11.492 8.39235 11.3718C8.57221 11.2516 8.78368 11.1875 9 11.1875C9.29008 11.1875 9.56828 11.3027 9.7734 11.5079C9.97852 11.713 10.0938 11.9912 10.0938 12.2812Z"
      fill="#D50525"
    />
  </svg>
</template>
