import { h, createApp, App } from "vue";
import singleSpaVue from "single-spa-vue";
import { beforeRouteEnterHook } from "@je-pc/auth";
import { trackPageTransition } from "@je-pc/tracking";
import { configFactory } from "@/configuration";
import MainApp from "@/App.vue";
import { routerFactory } from "@/router";
import { bootstrapLogging, bootstrapPinia } from "@/bootstrapping";
import { i18nFactory } from "@/i18n";
import { createApiClients } from "@/clients";
import Markdown from "vue3-markdown-it";

import "@/assets/tailwind.scss";
import "@justeattakeaway/pie-css";
import "@justeattakeaway/pie-button";

const init = async (app: App<Element>) => {
  const config = await configFactory(location.hostname);
  const router = routerFactory(beforeRouteEnterHook);
  const i18n = await i18nFactory(config);
  const { stampCardsApiClient } = createApiClients(config);

  if (trackPageTransition) {
    trackPageTransition(router);
  }

  bootstrapLogging(config);
  bootstrapPinia(app, stampCardsApiClient);

  app.provide("config", config);
  app.use(Markdown);
  app.use(router);
  app.use(i18n);
};

const vueLifecycle = singleSpaVue({
  createApp,
  async appOptions() {
    try {
      return h(MainApp);
    } catch (error) {
      console.error(error);
    }
  },
  handleInstance: async (app) => {
    await init(app);
  }
});

export const bootstrap = vueLifecycle.bootstrap;
export const mount = vueLifecycle.mount;
export const unmount = vueLifecycle.unmount;
