import {
  createRouter,
  createWebHistory,
  NavigationGuard,
  Router,
  RouteRecordRaw
} from "vue-router";
import { HomePage, JoinPage, OptOutPage } from "@/views";

export enum RouteName {
  Home = "home",
  Join = "join",
  Leave = "leave"
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.Home,
    component: HomePage,
    meta: {
      name: "stampcards",
      group: "marketing",
      title: "StampCards"
    }
  },
  {
    path: "/join",
    name: RouteName.Join,
    component: JoinPage,
    meta: {
      name: "stampcards",
      group: "marketing",
      title: "StampCards"
    }
  },
  {
    path: "/leave",
    name: RouteName.Leave,
    component: OptOutPage,
    meta: {
      name: "stampcards",
      group: "marketing",
      title: "StampCards"
    }
  },
  // default redirect to home page
  { path: "/:pathMatch(.*)*", redirect: "/" }
];

export const routerFactory = (authGuard: NavigationGuard): Router => {
  const router = createRouter({
    history: createWebHistory("/marketing/stamp-cards/"),
    routes
  });

  router.beforeEach((to, from, next) => {
    document.title = "StampCards";
    authGuard(to, from, next);
  });

  return router;
};
