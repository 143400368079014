<template>
  <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9842 7.0625H10.0154V11H11.9842V7.0625Z" fill="#F6C243" />
    <path
      d="M10.9998 14.9375C11.7247 14.9375 12.3123 14.3499 12.3123 13.625C12.3123 12.9001 11.7247 12.3125 10.9998 12.3125C10.2749 12.3125 9.68728 12.9001 9.68728 13.625C9.68728 14.3499 10.2749 14.9375 10.9998 14.9375Z"
      fill="#F6C243"
    />
    <path
      d="M19.111 18.5469H2.88853C2.47863 18.549 2.07599 18.4387 1.7243 18.2282C1.37261 18.0176 1.08529 17.7148 0.893531 17.3525C0.68883 16.9805 0.587003 16.5606 0.598538 16.1361C0.610073 15.7116 0.734552 15.2979 0.959156 14.9375L9.07041 1.94375C9.26799 1.60675 9.54964 1.32679 9.88782 1.13125C10.226 0.935698 10.6091 0.831254 10.9998 0.828125C11.3879 0.828539 11.7693 0.929008 12.1073 1.11983C12.4453 1.31065 12.7283 1.58538 12.9292 1.9175L21.0404 14.9375C21.2599 15.3068 21.3758 15.7285 21.3758 16.1581C21.3758 16.5878 21.2599 17.0094 21.0404 17.3787C20.8505 17.7264 20.572 18.0176 20.2331 18.2228C19.8942 18.4279 19.5071 18.5398 19.111 18.5469ZM10.9998 2.79688C10.9457 2.79589 10.8924 2.81013 10.8459 2.83798C10.7995 2.86582 10.7619 2.90616 10.7373 2.95438L2.62603 15.9613C2.58686 16.0291 2.56624 16.106 2.56624 16.1844C2.56624 16.2627 2.58686 16.3397 2.62603 16.4075C2.65099 16.4592 2.69018 16.5027 2.739 16.5329C2.78782 16.5632 2.84424 16.5788 2.90166 16.5781H19.111C19.1684 16.5788 19.2249 16.5632 19.2737 16.5329C19.3225 16.5027 19.3617 16.4592 19.3867 16.4075C19.4258 16.3397 19.4464 16.2627 19.4464 16.1844C19.4464 16.106 19.4258 16.0291 19.3867 15.9613L11.2623 2.95438C11.2377 2.90616 11.2 2.86582 11.1536 2.83798C11.1072 2.81013 11.0539 2.79589 10.9998 2.79688Z"
      fill="#F6C243"
    />
  </svg>
</template>
