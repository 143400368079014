import {
  BrowserContext,
  ConnectionContext,
  Console,
  JustEatLoggingService,
  justLog,
  Level
} from "@justeat/just-log";
import { IConfiguration } from "@/configuration";

export const bootstrapLogging = (config: IConfiguration) => {
  justLog
    .downToLevel(Level.Info)
    .forFeature({
      name: `${config.feature}`,
      environment: config.environment,
      tenant: config.tenant
    })
    .withContext(new ConnectionContext())
    .withContext(new BrowserContext())
    .to(new Console(true))
    .to(new JustEatLoggingService(config.loggingServiceUri));

  const logError = (source: string, message: string) => {
    justLog.fatal(message, {
      source
    });
  };

  window.addEventListener("error", function (event) {
    logError(event.message, event.error);
  });

  window.addEventListener("unhandledrejection", function (event) {
    const message = `Unhandled rejection in promise: ${event.reason}).\n${JSON.stringify(
      event.promise
    )}`;
    logError(message, "");
  });

  window.onerror = (message, url, lineNo, columnNo, error) => {
    const errorMessage = `${message} [${lineNo},${columnNo}] ${url}\n Error: ${JSON.stringify(
      error
    )} `;

    logError(errorMessage, error?.name);
  };

  const errorHandler = (error: unknown) => {
    const err = error as Error;
    logError(`${err.message}.\nStack: ${err.stack}`, err.name);
  };

  return {
    errorHandler
  };
};
