import { IConfiguration } from "@/configuration";
import { ApiClient } from "@je-pc/api";
import { IStampCardsApiClient, StampCardsApiClient } from ".";
import { IApiClient } from "./apiClient";

export const createApiClients = (config: IConfiguration) => {
  const apiClient: IApiClient = new ApiClient(config.smartGatewayUri);
  const stampCardsApiClient: IStampCardsApiClient = new StampCardsApiClient(apiClient);

  return {
    stampCardsApiClient
  };
};
