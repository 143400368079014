import {
  CampaignInfo,
  CampaignInsights,
  CampaignInsightsReportType,
  CampaignOverview,
  CampaignStatus,
  RestaurantInfo
} from "@/models";
import { defineStore } from "pinia";
import moment from "moment";
import { EventType } from "@/composables";

export const useStampCardsStore = defineStore("stampCards", {
  state: () => ({
    campaignInfo: null as CampaignInfo,
    restaurantInfo: null as RestaurantInfo,
    campaignInsights: null as CampaignInsights,
    campaignOverview: null as CampaignOverview,
    performanceLoaded: false,
    campaignLoaded: false,
    loadCampaignError: "",
    loadRestaurantError: "",
    loadInsightsError: "",
    subscribeToCampaignError: "",
    unsubscribeFromCampaignError: ""
  }),

  actions: {
    async loadCampaignInfo(refresh = false) {
      this.loadCampaignError = "";
      this.campaignLoaded = false;
      try {
        if (!this.campaignInfo || refresh) {
          this.campaignInfo = await this.stampCardsApiClient.getCampaignInfo();
        }
        if (!this.isSubscribed) {
          this.analytics.pushEvent(EventType.HOME_PAGE_VIEW, this.trackingEventData);

          if (!this.canSubscribe) {
            this.analytics.pushEvent(EventType.OPT_IN_BLOCKED_PAGE_VIEW, this.trackingEventData);
          }
        }
      } catch (error) {
        this.campaignInfo = undefined;
        this.analytics.pushEvent(EventType.HOME_PAGE_ERROR, this.trackingEventData);
        this.loadCampaignError = "Unable to load StampCards campaign, please try again later";
      } finally {
        this.campaignLoaded = true;
      }
    },

    async loadRestaurantInfo() {
      this.loadRestaurantError = "";
      try {
        if (!this.restaurantInfo) {
          this.restaurantInfo = await this.stampCardsApiClient.getRestaurantInfo();
        }
      } catch (error) {
        this.restaurantInfo = undefined;
        this.analytics.pushEvent(EventType.HOME_PAGE_ERROR, this.trackingEventData);
        this.loadRestaurantError = "Unable to load Restaurant details, please try again later";
      }
    },

    async loadCampaignInsights(reportType: CampaignInsightsReportType) {
      this.loadInsightsError = "";
      this.performanceLoaded = false;
      try {
        if (!this.campaignOverview) {
          const [campaignInsights, campaignOverview] = await Promise.all([
            this.stampCardsApiClient.getCampaignInsights(reportType),
            this.stampCardsApiClient.getCampaignOverview()
          ]);
          this.campaignInsights = campaignInsights;
          this.campaignOverview = campaignOverview;
        } else {
          this.campaignInsights = await this.stampCardsApiClient.getCampaignInsights(reportType);
        }

        if (this.campaignInsights?.campaign_id) {
          this.analytics.pushEvent(EventType.RESULTS_PAGE_VIEW, { report: reportType });
        } else {
          this.analytics.pushEvent(EventType.NO_RESULTS_PAGE_VIEW, { report: reportType });
        }
      } catch (error) {
        this.campaignInsights = null;
        this.analytics.pushEvent(EventType.RESULTS_PAGE_ERROR, { report: reportType });
        this.loadInsightsError = "Unable to load Campaign Insights, please try again later";
      } finally {
        this.performanceLoaded = true;
      }
    },

    async subscribeToStampCardsCampaign() {
      this.subscribeToCampaignError = "";
      try {
        const initialStatus = this.campaignInfo.status;
        await this.stampCardsApiClient.subscribeToStampCardsCampaign();
        await Promise.all([
          this.loadCampaignInfo(true),
          this.loadCampaignInsights(CampaignInsightsReportType.LastWeek)
        ]);
        if (initialStatus === this.campaignInfo.status) {
          setTimeout(async () => await this.loadCampaignInfo(true), 500);
        }
        this.analytics.pushEvent(EventType.OPT_IN_CONFIRM, this.trackingEventData);
      } catch (error) {
        this.analytics.pushEvent(EventType.OPT_IN_ERROR, this.trackingEventData);
        this.subscribeToCampaignError =
          "Unable to subscribe to StampCards campaign, please try again later";
      }
    },

    async subscribeBackToStampCardsCampaign() {
      this.subscribeToCampaignError = "";
      try {
        const initialStatus = this.campaignInfo.status;
        await this.stampCardsApiClient.subscribeToStampCardsCampaign();
        await this.loadCampaignInfo(true);
        if (initialStatus === this.campaignInfo.status) {
          setTimeout(async () => await this.loadCampaignInfo(true), 500);
        }
        this.analytics.pushEvent(EventType.OPT_BACK_IN_CONFIRM, this.trackingEventData);
      } catch (error) {
        this.analytics.pushEvent(EventType.OPT_BACK_IN_ERROR, this.trackingEventData);
        this.subscribeToCampaignError =
          "Unable to subscribe to StampCards campaign, please try again later";
      }
    },

    async unsubscribeFromStampCardsCampaign(reason: string) {
      this.unsubscribeFromCampaignError = "";
      try {
        await this.stampCardsApiClient.unsubscribeFromStampCardsCampaign(reason);
        await this.loadCampaignInfo(true);
        this.analytics.pushEvent(EventType.OPT_OUT_CONFIRM, { reason });
      } catch (error) {
        this.analytics.pushEvent(EventType.OPT_OUT_ERROR, { reason });
        this.unsubscribeFromCampaignError =
          "Unable to unsubscribe from StampCards campaign, please try again later";
      }
    }
  },

  getters: {
    isSubscribed: ({ campaignInfo }) => {
      return campaignInfo && campaignInfo.status !== CampaignStatus.Unsubscribed;
    },

    subscriptionDate({ campaignInfo }) {
      if (this.isSubscribed) {
        return campaignInfo.statusChangeLogs.find((l) => l.status === CampaignStatus.Subscribed)
          ?.changedAt;
      }
      return undefined;
    },

    closestSubscribeDate({ campaignInfo }) {
      if (!this.isSubscribed) {
        const canOptIn = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.Subscribed
        );

        if (canOptIn) {
          if (canOptIn.fromDateTime === null) {
            return undefined;
          }
          if (canOptIn.fromDateTime) {
            return moment.parseZone(canOptIn.fromDateTime).toISOString(true);
          }
        }
      }
      return undefined;
    },

    closestUnsubscribeDate({ campaignInfo }) {
      if (this.isSubscribed) {
        const canOptOut = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.OptOutRequested
        );

        if (canOptOut) {
          if (canOptOut.fromDateTime === null) {
            return undefined;
          }
          if (canOptOut.fromDateTime) {
            return moment.parseZone(canOptOut.fromDateTime).toISOString(true);
          }
        }
      }
      return undefined;
    },

    canSubscribe({ campaignInfo }) {
      if (!this.isSubscribed) {
        const canOptIn = campaignInfo?.eligibleStatusChanges.find(
          (s) => s.status === CampaignStatus.Subscribed
        );

        if (!canOptIn) {
          return false;
        }
        if (canOptIn.fromDateTime === null) {
          return true;
        }

        if (canOptIn.fromDateTime) {
          const closest = moment.parseZone(canOptIn.fromDateTime);
          return moment.utc().utcOffset(closest.utcOffset()) > closest;
        }
      }
      return false;
    },

    canUnsubscribe({ campaignInfo }) {
      if (this.isSubscribed) {
        const canOptOut = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.OptOutRequested
        );

        if (!canOptOut) {
          return false;
        }

        if (canOptOut.fromDateTime === null) {
          return true;
        }

        if (canOptOut.fromDateTime) {
          const from = moment.parseZone(canOptOut.fromDateTime);
          return moment.utc().utcOffset(from.utcOffset()) > from;
        }
      }
      return false;
    },

    canOptBackIn({ campaignInfo }) {
      if (campaignInfo?.status === CampaignStatus.OptOutRequested) {
        const canSubscribe = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.Subscribed
        );

        const optOutConfirmed = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.OptOutConfirmed
        );

        if (!canSubscribe || !optOutConfirmed) {
          return false;
        }
        return true;
      }
      return false;
    },

    optBackInDate({ campaignInfo }) {
      if (campaignInfo?.status === CampaignStatus.OptOutRequested) {
        const canSubscribe = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.Subscribed
        );

        const optOutConfirmed = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.OptOutConfirmed
        );

        if (!canSubscribe || !optOutConfirmed) {
          return undefined;
        }
        return moment.parseZone(canSubscribe.toDateTime || optOutConfirmed.fromDateTime);
      }
      return undefined;
    },

    leavingDate({ campaignInfo }) {
      if (campaignInfo?.status === CampaignStatus.OptOutConfirmed) {
        const unsubscribeState = campaignInfo?.eligibleStatusChanges.find(
          (l) => l.status === CampaignStatus.Unsubscribed
        );

        if (unsubscribeState) {
          return moment.parseZone(unsubscribeState.fromDateTime);
        }
      }
      return undefined;
    },

    trackingEventData() {
      return {
        isSubscribed: this.isSubscribed,
        closestSubscribeDate: this.closestSubscribeDate,
        closestUnsubscribeDate: this.closestUnsubscribeDate,
        subscriptionDate: this.subscriptionDate
      };
    }
  }
});
