import { App, markRaw } from "vue";
import { createPinia } from "pinia";
import { IStampCardsApiClient } from "@/clients";
import { EventType, useAnalytics } from "@/composables";

export const bootstrapPinia = (app: App<Element>, stampCardsApiClient: IStampCardsApiClient) => {
  const pinia = createPinia();
  const analytics = useAnalytics();

  pinia.use(({ store }) => {
    store.stampCardsApiClient = markRaw(stampCardsApiClient);
    store.analytics = markRaw(analytics);
  });

  app.use(pinia);
};

declare module "pinia" {
  export interface PiniaCustomProperties {
    stampCardsApiClient: IStampCardsApiClient;
    analytics: { pushEvent: (eventType: EventType, eventData: unknown) => void };
  }
}
