import { IConfiguration } from "@/configuration";

export const currency =
  (options: IConfiguration) =>
  (value: number | string, formatOptions?: { roundDecimals?: boolean }): string => {
    if (value === null || value === undefined) {
      return "-";
    }

    const num = Number(value);
    const number = isNaN(num) ? 0 : num;

    const formatted = new Intl.NumberFormat(options.locale, {
      style: "currency",
      currency: options.currency,
      maximumFractionDigits: formatOptions?.roundDecimals ? 0 : 2,
      minimumFractionDigits: formatOptions?.roundDecimals ? 0 : 2
    }).format(number);

    return formatted;
  };
