import { HostNameMapper, IConfiguration } from "@/configuration";

export const configFactory = async (hostname: string): Promise<IConfiguration> => {
  const { tenant, environment } = new HostNameMapper(hostname).getHostSettings();

  const config = (await import(`./config.${environment}.${tenant}.js`)).default;
  config.isProduction = (config.isProduction ?? "false") === "true" ? true : false;

  return config;
};
