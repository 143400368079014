import { IConfiguration } from "@/configuration";
import dayjs, { Dayjs } from "dayjs";

export const shortDate =
  (options: IConfiguration) =>
  (value: Dayjs | Date | string): string => {
    if (value === null || value === undefined) {
      return "";
    }

    return dayjs(value as any).format(options.shortDateFormat);
  };
