/************************************************************
 * This file is auto generated, don't edit it directly
 * The content is generated based on deploy/config.js file
 * To generate this file run command: 'npm run generate:resources'
 ***********************************************************/
type HostSettings = { tenant: string; environment: string };

export class HostNameMapper {
  constructor(private hostname: string) {}
  private hostNameMappings: { [host: string]: HostSettings } = {
    "uk-partnercentrespa-qa28.cogpart.je-labs.com": { tenant: "uk", environment: "qa28" },
    "es-partnercentrespa-qa29.cogpart.je-labs.com": { tenant: "es", environment: "qa29" },
    "ie-partnercentrespa-qa29.cogpart.je-labs.com": { tenant: "ie", environment: "qa29" },
    "it-partnercentrespa-qa29.cogpart.je-labs.com": { tenant: "it", environment: "qa29" },
    "es-partnercentrespa-qa11.bapps.je-labs.com": { tenant: "es", environment: "qa11" },
    "ie-partnercentrespa-qa11.bapps.je-labs.com": { tenant: "ie", environment: "qa11" },
    "it-partnercentrespa-qa11.bapps.je-labs.com": { tenant: "it", environment: "qa11" },
    "au-partnercentrespa-qa53.aus-qa.je-labs.com": { tenant: "au", environment: "qa53" },
    "nz-partnercentrespa-qa53.aus-qa.je-labs.com": { tenant: "nz", environment: "qa53" },
    "uk-partnercentrespa-staging-uk.staging-uk.je-labs.com": {
      tenant: "uk",
      environment: "staging-uk"
    },
    "es-partnercentrespa-ieng-staging.ieng-staging.je-labs.com": {
      tenant: "es",
      environment: "ieng-staging"
    },
    "ie-partnercentrespa-ieng-staging.ieng-staging.je-labs.com": {
      tenant: "ie",
      environment: "ieng-staging"
    },
    "it-partnercentrespa-ieng-staging.ieng-staging.je-labs.com": {
      tenant: "it",
      environment: "ieng-staging"
    },
    "au-partnercentrespa-qa55.au-justeat-1-staging.je-labs.com": {
      tenant: "au",
      environment: "qa55"
    },
    "nz-partnercentrespa-qa55.au-justeat-1-staging.je-labs.com": {
      tenant: "nz",
      environment: "qa55"
    },
    "partner-centre.just-eat.co.uk": { tenant: "uk", environment: "production" },
    "partner-centre.just-eat.es": { tenant: "es", environment: "i18n-production" },
    "partner-centre.just-eat.ie": { tenant: "ie", environment: "i18n-production" },
    "partner-centre.justeat.it": { tenant: "it", environment: "i18n-production" },
    "partner-centre.menulog.com.au": { tenant: "au", environment: "aus-production" },
    "partner-centre.menulog.co.nz": { tenant: "nz", environment: "aus-production" }
  };

  public getHostSettings(): HostSettings {
    return this.hostNameMappings[this.hostname];
  }
}
