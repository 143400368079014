import { currency, shortDate } from "@/filters";
import { useConfig } from "./useConfig";

export function useFilters() {
  const config = useConfig();

  return {
    currency: currency(config),
    shortDate: shortDate(config)
  };
}
