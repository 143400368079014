export enum CampaignStatus {
  Unsubscribed = "Unsubscribed",
  Subscribed = "Subscribed",
  OptOutRequested = "OptOutRequested",
  OptOutConfirmed = "OptOutConfirmed"
}

export interface CampaignInfo {
  id: string;
  status: CampaignStatus;
  statusChangeLogs: Array<{
    changedAt: Date;
    status: CampaignStatus;
  }>;
  eligibleStatusChanges: Array<{
    fromDateTime: Date;
    toDateTime?: Date;
    status: CampaignStatus;
  }>;
  active: boolean;
  statusReason?: string;
}
