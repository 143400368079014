/************************************************************
 * This file is auto generated, don't edit it directly
 * The content is generated based on './scr/i18n/resources.en.js' file
 * To generate this file run command: 'npm run generate:resources'
 ***********************************************************/
import { useI18n } from "vue-i18n";
export const useTranslations = () => {
  const i18n = useI18n();
  return {
    benefits: {
      cost: {
        description() {
          return i18n.t("benefits.cost.description").toString();
        },
        title() {
          return i18n.t("benefits.cost.title").toString();
        }
      },
      freeOptIn: {
        description() {
          return i18n.t("benefits.freeOptIn.description").toString();
        },
        title() {
          return i18n.t("benefits.freeOptIn.title").toString();
        }
      },
      metrics: {
        customerLoyalty() {
          return i18n.t("benefits.metrics.customerLoyalty").toString();
        },
        orderIncrease() {
          return i18n.t("benefits.metrics.orderIncrease").toString();
        }
      },
      section: {
        text() {
          return i18n.t("benefits.section.text").toString();
        },
        title() {
          return i18n.t("benefits.section.title").toString();
        }
      },
      visibility: {
        description() {
          return i18n.t("benefits.visibility.description").toString();
        },
        title() {
          return i18n.t("benefits.visibility.title").toString();
        }
      }
    },
    discountBreakdown: {
      discountedOrder: {
        discountPercent() {
          return i18n.t("discountBreakdown.discountedOrder.discountPercent").toString();
        },
        discountValue() {
          return i18n.t("discountBreakdown.discountedOrder.discountValue").toString();
        },
        title() {
          return i18n.t("discountBreakdown.discountedOrder.title").toString();
        }
      },
      orderNumber: {
        1() {
          return i18n.t("discountBreakdown.orderNumber.1").toString();
        },
        2() {
          return i18n.t("discountBreakdown.orderNumber.2").toString();
        },
        3() {
          return i18n.t("discountBreakdown.orderNumber.3").toString();
        },
        4() {
          return i18n.t("discountBreakdown.orderNumber.4").toString();
        },
        5() {
          return i18n.t("discountBreakdown.orderNumber.5").toString();
        },
        6() {
          return i18n.t("discountBreakdown.orderNumber.6").toString();
        },
        7() {
          return i18n.t("discountBreakdown.orderNumber.7").toString();
        },
        8() {
          return i18n.t("discountBreakdown.orderNumber.8").toString();
        },
        9() {
          return i18n.t("discountBreakdown.orderNumber.9").toString();
        },
        10() {
          return i18n.t("discountBreakdown.orderNumber.10").toString();
        },
        11() {
          return i18n.t("discountBreakdown.orderNumber.11").toString();
        },
        12() {
          return i18n.t("discountBreakdown.orderNumber.12").toString();
        }
      },
      previousOrders: {
        title() {
          return i18n.t("discountBreakdown.previousOrders.title").toString();
        },
        totalSpend() {
          return i18n.t("discountBreakdown.previousOrders.totalSpend").toString();
        }
      },
      section: {
        note() {
          return i18n.t("discountBreakdown.section.note").toString();
        },
        text() {
          return i18n.t("discountBreakdown.section.text").toString();
        },
        title() {
          return i18n.t("discountBreakdown.section.title").toString();
        }
      }
    },
    faq: {
      section: {
        text() {
          return i18n.t("faq.section.text").toString();
        },
        title() {
          return i18n.t("faq.section.title").toString();
        }
      }
    },
    general: {
      app: {
        title() {
          return i18n.t("general.app.title").toString();
        }
      },
      button: {
        back() {
          return i18n.t("general.button.back").toString();
        },
        cancel() {
          return i18n.t("general.button.cancel").toString();
        },
        confirm() {
          return i18n.t("general.button.confirm").toString();
        }
      },
      pageTitles: {
        joinStampCards() {
          return i18n.t("general.pageTitles.joinStampCards").toString();
        },
        performance() {
          return i18n.t("general.pageTitles.performance").toString();
        },
        stampCards() {
          return i18n.t("general.pageTitles.stampCards").toString();
        }
      }
    },
    howItWorks: {
      discount: {
        description() {
          return i18n.t("howItWorks.discount.description").toString();
        },
        title() {
          return i18n.t("howItWorks.discount.title").toString();
        }
      },
      repeatOrders: {
        description() {
          return i18n.t("howItWorks.repeatOrders.description").toString();
        },
        title() {
          return i18n.t("howItWorks.repeatOrders.title").toString();
        }
      },
      section: {
        title() {
          return i18n.t("howItWorks.section.title").toString();
        }
      },
      stampPerOrder: {
        description() {
          return i18n.t("howItWorks.stampPerOrder.description").toString();
        },
        title() {
          return i18n.t("howItWorks.stampPerOrder.title").toString();
        }
      }
    },
    joinStampCards: {
      action: {
        title() {
          return i18n.t("joinStampCards.action.title").toString();
        }
      },
      failedToSubscribeError: {
        text() {
          return i18n.t("joinStampCards.failedToSubscribeError.text").toString();
        },
        title() {
          return i18n.t("joinStampCards.failedToSubscribeError.title").toString();
        }
      },
      section: {
        text() {
          return i18n.t("joinStampCards.section.text").toString();
        },
        title() {
          return i18n.t("joinStampCards.section.title").toString();
        }
      },
      summary: {
        discountGiven(orderNumber: string) {
          return i18n
            .t("joinStampCards.summary.discountGiven", { orderNumber: orderNumber })
            .toString();
        },
        discountPercent() {
          return i18n.t("joinStampCards.summary.discountPercent").toString();
        },
        title() {
          return i18n.t("joinStampCards.summary.title").toString();
        }
      },
      terms: {
        accept(url: string) {
          return i18n.t("joinStampCards.terms.accept", { url: url }).toString();
        },
        note() {
          return i18n.t("joinStampCards.terms.note").toString();
        },
        title() {
          return i18n.t("joinStampCards.terms.title").toString();
        }
      },
      validation: {
        termsNotAccepted() {
          return i18n.t("joinStampCards.validation.termsNotAccepted").toString();
        }
      }
    },
    optOut: {
      action: {
        title() {
          return i18n.t("optOut.action.title").toString();
        }
      },
      confirmation: {
        text() {
          return i18n.t("optOut.confirmation.text").toString();
        },
        title() {
          return i18n.t("optOut.confirmation.title").toString();
        }
      },
      error: {
        failedToUnsubscribe() {
          return i18n.t("optOut.error.failedToUnsubscribe").toString();
        }
      },
      notes: {
        text() {
          return i18n.t("optOut.notes.text").toString();
        },
        title() {
          return i18n.t("optOut.notes.title").toString();
        }
      },
      reason: {
        closingBusiness() {
          return i18n.t("optOut.reason.closingBusiness").toString();
        },
        haveEnoughCustomers() {
          return i18n.t("optOut.reason.haveEnoughCustomers").toString();
        },
        haveMyOwnScheme() {
          return i18n.t("optOut.reason.haveMyOwnScheme").toString();
        },
        title() {
          return i18n.t("optOut.reason.title").toString();
        },
        tooBusy() {
          return i18n.t("optOut.reason.tooBusy").toString();
        },
        tooExpensive() {
          return i18n.t("optOut.reason.tooExpensive").toString();
        }
      },
      validation: {
        noReason() {
          return i18n.t("optOut.validation.noReason").toString();
        },
        noReasonMessage() {
          return i18n.t("optOut.validation.noReasonMessage").toString();
        }
      }
    },
    performance: {
      avgOrderValue: {
        text(increasePercent: string) {
          return i18n
            .t("performance.avgOrderValue.text", { increasePercent: increasePercent })
            .toString();
        },
        title() {
          return i18n.t("performance.avgOrderValue.title").toString();
        },
        tooltip() {
          return i18n.t("performance.avgOrderValue.tooltip").toString();
        }
      },
      fetchError: {
        text() {
          return i18n.t("performance.fetchError.text").toString();
        },
        title() {
          return i18n.t("performance.fetchError.title").toString();
        }
      },
      filter: {
        dateRange(from: string, to: string) {
          return i18n.t("performance.filter.dateRange", { from: from, to: to }).toString();
        },
        resultsFrom() {
          return i18n.t("performance.filter.resultsFrom").toString();
        }
      },
      joinedSince: {
        text() {
          return i18n.t("performance.joinedSince.text").toString();
        },
        title() {
          return i18n.t("performance.joinedSince.title").toString();
        }
      },
      leavingSchemeMessage: {
        text() {
          return i18n.t("performance.leavingSchemeMessage.text").toString();
        },
        title(date: string) {
          return i18n.t("performance.leavingSchemeMessage.title", { date: date }).toString();
        }
      },
      noData: {
        text() {
          return i18n.t("performance.noData.text").toString();
        },
        title() {
          return i18n.t("performance.noData.title").toString();
        }
      },
      optBackInConfirmation: {
        text() {
          return i18n.t("performance.optBackInConfirmation.text").toString();
        },
        title() {
          return i18n.t("performance.optBackInConfirmation.title").toString();
        }
      },
      optedOutMessage: {
        button() {
          return i18n.t("performance.optedOutMessage.button").toString();
        },
        description(noticeExpiry: string, optInDeadline: string) {
          return i18n
            .t("performance.optedOutMessage.description", {
              noticeExpiry: noticeExpiry,
              optInDeadline: optInDeadline
            })
            .toString();
        },
        title() {
          return i18n.t("performance.optedOutMessage.title").toString();
        }
      },
      reports: {
        allTime() {
          return i18n.t("performance.reports.allTime").toString();
        },
        last2Weeks() {
          return i18n.t("performance.reports.last2Weeks").toString();
        },
        lastMonth() {
          return i18n.t("performance.reports.lastMonth").toString();
        },
        lastWeek() {
          return i18n.t("performance.reports.lastWeek").toString();
        }
      },
      returningCustomers: {
        text(percent: string) {
          return i18n.t("performance.returningCustomers.text", { percent: percent }).toString();
        },
        title() {
          return i18n.t("performance.returningCustomers.title").toString();
        },
        tooltip() {
          return i18n.t("performance.returningCustomers.tooltip").toString();
        }
      },
      stampProgress: {
        discountsNotUsed() {
          return i18n.t("performance.stampProgress.discountsNotUsed").toString();
        },
        discountsUsed() {
          return i18n.t("performance.stampProgress.discountsUsed").toString();
        },
        stampCollected() {
          return i18n.t("performance.stampProgress.stampCollected").toString();
        },
        text() {
          return i18n.t("performance.stampProgress.text").toString();
        },
        title() {
          return i18n.t("performance.stampProgress.title").toString();
        },
        totalCustomers() {
          return i18n.t("performance.stampProgress.totalCustomers").toString();
        }
      },
      stampsGiven: {
        text(percent: string) {
          return i18n.t("performance.stampsGiven.text", { percent: percent }).toString();
        },
        title() {
          return i18n.t("performance.stampsGiven.title").toString();
        },
        tooltip() {
          return i18n.t("performance.stampsGiven.tooltip").toString();
        }
      },
      totalEarned: {
        text() {
          return i18n.t("performance.totalEarned.text").toString();
        },
        title() {
          return i18n.t("performance.totalEarned.title").toString();
        }
      },
      totalSpent: {
        text(count: string) {
          return i18n.t("performance.totalSpent.text", { count: count }).toString();
        },
        title() {
          return i18n.t("performance.totalSpent.title").toString();
        }
      },
      youMade: {
        text(madeAmount: string, discountAmount: string) {
          return i18n
            .t("performance.youMade.text", {
              madeAmount: madeAmount,
              discountAmount: discountAmount
            })
            .toString();
        }
      }
    },
    sideInformation: {
      button: {
        checkFaq() {
          return i18n.t("sideInformation.button.checkFaq").toString();
        },
        howItWorks() {
          return i18n.t("sideInformation.button.howItWorks").toString();
        },
        leave() {
          return i18n.t("sideInformation.button.leave").toString();
        }
      },
      haveQuestions: {
        text(email: string, url: string) {
          return i18n
            .t("sideInformation.haveQuestions.text", { email: email, url: url })
            .toString();
        },
        title() {
          return i18n.t("sideInformation.haveQuestions.title").toString();
        }
      },
      howItWorks: {
        title() {
          return i18n.t("sideInformation.howItWorks.title").toString();
        }
      },
      optOut: {
        text() {
          return i18n.t("sideInformation.optOut.text").toString();
        },
        title() {
          return i18n.t("sideInformation.optOut.title").toString();
        }
      }
    },
    whatIsStampCards: {
      backOptInMessage: {
        description() {
          return i18n.t("whatIsStampCards.backOptInMessage.description").toString();
        },
        title(date: string) {
          return i18n.t("whatIsStampCards.backOptInMessage.title", { date: date }).toString();
        }
      },
      button: {
        join() {
          return i18n.t("whatIsStampCards.button.join").toString();
        }
      },
      readyToJoin: {
        description() {
          return i18n.t("whatIsStampCards.readyToJoin.description").toString();
        },
        title() {
          return i18n.t("whatIsStampCards.readyToJoin.title").toString();
        }
      },
      summary: {
        description() {
          return i18n.t("whatIsStampCards.summary.description").toString();
        },
        title() {
          return i18n.t("whatIsStampCards.summary.title").toString();
        }
      }
    }
  };
};
