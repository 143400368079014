import {
  CampaignInfo,
  CampaignInsights,
  CampaignInsightsReportType,
  CampaignOverview,
  RestaurantInfo
} from "@/models";
import { IApiClient } from "./apiClient";

export interface IStampCardsApiClient {
  getCampaignInfo(): Promise<CampaignInfo>;
  getRestaurantInfo(): Promise<RestaurantInfo>;
  getCampaignOverview(): Promise<CampaignOverview>;
  subscribeToStampCardsCampaign(): Promise<any>;
  unsubscribeFromStampCardsCampaign(reason: string): Promise<any>;
  getCampaignInsights(reportType: CampaignInsightsReportType): Promise<CampaignInsights>;
}

export interface CampaignInsightsResponse {
  reportData?: CampaignInsights[];
}

export interface CampaignOverviewResponse {
  reportData?: CampaignOverview[];
}

export class StampCardsApiClient implements IStampCardsApiClient {
  constructor(private readonly client: IApiClient) {}

  private get STAMPCARDS_CAMPAIGN_ID() {
    return "stampCardLoyalty";
  }

  public async getCampaignInfo(): Promise<CampaignInfo> {
    return this.client.get<CampaignInfo>(
      `/applications/partneroffers/{tenant}/me/campaigns/${this.STAMPCARDS_CAMPAIGN_ID}`,
      {
        headers: { Accept: "application/json;v=2" }
      }
    );
  }

  public async getRestaurantInfo(): Promise<RestaurantInfo> {
    const response = await this.client.get<RestaurantInfo>("/restaurants/{tenant}/{restaurantId}");

    if (response) {
      response.logoUrl = `https:${response}`;
    }
    return response;
  }

  public async getCampaignInsights(
    reportType: CampaignInsightsReportType
  ): Promise<CampaignInsights> {
    try {
      const response = await this.client.get<CampaignInsightsResponse>(
        `reports/{tenant}/restaurants/{restaurantId}/marketing/${reportType}`
      );

      return response.reportData?.find((r) => r.campaign_id === this.STAMPCARDS_CAMPAIGN_ID);
    } catch (error: any) {
      if (error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  }

  public async getCampaignOverview(): Promise<CampaignOverview> {
    try {
      const response = await this.client.get<CampaignOverviewResponse>(
        "reports/{tenant}/restaurants/{restaurantId}/marketing/offer-campaigns-insights-overview"
      );

      return response.reportData?.find((r) => r.campaign_id === this.STAMPCARDS_CAMPAIGN_ID);
    } catch (error: any) {
      if (error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  }

  public async subscribeToStampCardsCampaign() {
    const data = [
      {
        op: "replace",
        path: "/status",
        value: "Subscribed"
      }
    ];

    return this.updateCampaignStatus(data);
  }

  public async unsubscribeFromStampCardsCampaign(reason: string) {
    const data = [
      {
        op: "replace",
        path: "/status",
        value: "OptOutRequested"
      },
      {
        op: "replace",
        path: "/statusReason",
        value: reason
      }
    ];

    return this.updateCampaignStatus(data);
  }

  private async updateCampaignStatus(data: Array<{ op: string; path: string; value: string }>) {
    return this.client.patch(
      `/applications/partneroffers/{tenant}/me/campaigns/${this.STAMPCARDS_CAMPAIGN_ID}`,
      data,
      {
        headers: { "Content-Type": "application/json-patch+json;v=2" }
      }
    );
  }
}
