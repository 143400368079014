export enum EventType {
  RESULTS_PAGE_VIEW = "pushResultsPageView",
  RESULTS_PAGE_ERROR = "pushResultsPageError",
  HOME_PAGE_VIEW = "pushHomePageView",
  HOME_PAGE_ERROR = "pushHomePageError",
  NO_RESULTS_PAGE_VIEW = "pushNoResultsPageView",
  OPT_IN_BLOCKED_PAGE_VIEW = "pushOptInBlockedPageViewEvent",
  TC_ACCEPT = "pushAcceptTermsAndConditionsEvent",
  OPT_IN_PAGE_OPEN = "pushOpenOptInPageEvent",
  OPT_IN_CONFIRM = "pushConfirmOptInEvent",
  OPT_IN_CANCEL = "pushCancelOptInEvent",
  OPT_IN_ERROR = "pushOptInErrorEvent",
  OPT_OUT_PAGE_OPEN = "pushStartOptOutEvent",
  OPT_OUT_CONFIRM = "pushConfirmOptOutEvent",
  OPT_OUT_CANCEL = "pushCancelOptOutEvent",
  OPT_OUT_ERROR = "pushOptOutErrorEvent",
  HOW_IT_WORKS_MODAL_OPEN = "pushHowItWorksModalViewEvent",
  HOW_IT_WORKS_MODAL_CLOSE = "pushHowItWorksModalCloseEvent",
  FAQ_MODAL_OPEN = "pushFaqModalViewEvent",
  FAQ_MODAL_CLOSE = "pushFaqModalCloseEvent",
  OPT_BACK_IN_MODAL_OPEN = "pushOpenOptBackInModalEvent",
  OPT_BACK_IN_CONFIRM = "pushConfirmOptBackInEvent",
  OPT_BACK_IN_CANCEL = "pushCancelOptBackInEvent",
  OPT_BACK_IN_ERROR = "pushOptBackInErrorEvent"
}

const eventMap = {
  [EventType.RESULTS_PAGE_VIEW]: {
    action: "stampcard_results",
    label: "view"
  },
  [EventType.RESULTS_PAGE_ERROR]: {
    action: "stampcard_results",
    label: "error"
  },
  [EventType.HOME_PAGE_VIEW]: {
    action: "stampcard_home",
    label: "view"
  },
  [EventType.HOME_PAGE_ERROR]: {
    action: "stampcard_home",
    label: "error"
  },
  [EventType.NO_RESULTS_PAGE_VIEW]: {
    action: "stampcard_no_results_yet",
    label: "view"
  },
  [EventType.OPT_IN_BLOCKED_PAGE_VIEW]: {
    action: "stampcard_opt_in_blocked",
    label: "view"
  },
  [EventType.TC_ACCEPT]: {
    action: "form_stampcard_opt_in_tc",
    label: "start"
  },
  [EventType.OPT_IN_PAGE_OPEN]: {
    action: "form_stampcard_opt_in",
    label: "start"
  },
  [EventType.OPT_IN_CONFIRM]: {
    action: "form_stampcard_opt_in",
    label: "success"
  },
  [EventType.OPT_IN_CANCEL]: {
    action: "form_stampcard_opt_in",
    label: "cancel"
  },
  [EventType.OPT_IN_ERROR]: {
    action: "form_stampcard_opt_in",
    label: "error"
  },
  [EventType.OPT_OUT_PAGE_OPEN]: {
    action: "form_stampcard_opt_out",
    label: "start"
  },
  [EventType.OPT_OUT_CONFIRM]: {
    action: "form_stampcard_opt_out",
    label: "success"
  },
  [EventType.OPT_OUT_CANCEL]: {
    action: "form_stampcard_opt_out",
    label: "cancel"
  },
  [EventType.OPT_OUT_ERROR]: {
    action: "form_stampcard_opt_out",
    label: "error"
  },
  [EventType.HOW_IT_WORKS_MODAL_OPEN]: {
    action: "form_how_it_works",
    label: "view"
  },
  [EventType.HOW_IT_WORKS_MODAL_CLOSE]: {
    action: "form_how_it_works",
    label: "close"
  },
  [EventType.FAQ_MODAL_OPEN]: {
    action: "form_faq",
    label: "view"
  },
  [EventType.FAQ_MODAL_CLOSE]: {
    action: "form_faq",
    label: "close"
  },
  [EventType.OPT_BACK_IN_MODAL_OPEN]: {
    action: "form_stampcard_optbackin",
    label: "start"
  },
  [EventType.OPT_BACK_IN_CONFIRM]: {
    action: "form_stampcard_optbackin",
    label: "success"
  },
  [EventType.OPT_BACK_IN_CANCEL]: {
    action: "form_stampcard_optbackin",
    label: "cancel"
  },
  [EventType.OPT_BACK_IN_ERROR]: {
    action: "form_stampcard_optbackin",
    label: "error"
  }
};

const ACTION_SUFFIX = "_v2";

export function useAnalytics() {
  const pushEvent = (eventType: EventType, eventData: unknown) => {
    const e = eventMap[eventType];
    const dataLayer = window.dataLayer || [];

    const event = {
      event: "trackEvent",
      eventCategory: "engagement",
      eventAction: `${e.action}${ACTION_SUFFIX}`,
      eventLabel: e.label,
      eventData: eventData
    };

    dataLayer.push(event);
  };

  return {
    pushEvent
  };
}
