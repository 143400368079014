import * as VueI18n from "vue-i18n";
import { IConfiguration } from "@/configuration";

interface ILocaleMessages {
  [key: string]: string | ILocaleMessages;
}

export const i18nFactory = async (config: IConfiguration) => {
  const messages = {
    [config.localeBase]: (await import(`./resources.${config.localeBase}.js`))
      .default as ILocaleMessages,
    [config.locale]: (await import(`./resources.${config.locale}.js`)).default as ILocaleMessages
  };

  return VueI18n.createI18n({
    legacy: false,
    locale: config.locale,
    messages: Object.freeze(messages),
    fallbackLocale: "en",
    fallbackWarn: false,
    missingWarn: false,
    numberFormats: {
      [config.locale]: {
        currency: {
          style: "currency",
          currency: config.currency
        }
      }
    }
  });
};
