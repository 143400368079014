<template>
  <span class="loader"></span>
</template>

<style lang="css" scoped>
  .loader {
    width: 32px;
    height: 32px;
    border: 4px solid #fbcaa8;
    border-bottom-color: #f36806;
    border-right-color: #f36806;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
